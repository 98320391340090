import React, { useState, useEffect, useRef } from "react";
import CreateHospital from "../Create/CreateHospital";
import EditHospital from "../Edit/EditHospital";
import GerirContactosHospital from "./GerirContactosHospital";
import { FaTrash } from "react-icons/fa";
import { Modal, Button } from "react-bootstrap";
import ScrollToTopButton from "../Utils/ScrollToTopButton";
import ListagemHospitais from "../Listagens/ListagemHospitais";
import { Modal as BootstrapModal } from "react-bootstrap";
import { Editor } from "@tinymce/tinymce-react";

function GerirHospitais(anoLetivo) {
  const [hospitais, setHospitais] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedHospital, setSelectedHospital] = useState(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showEmailButton, setShowEmailButton] = useState(false);
  const [showConfirmationModalEmail, setShowConfirmationModalEmail] =
    useState(false);
  const [htmlBody, setHtmlBody] = useState([]);
  const htmlBodyRef = useRef(htmlBody);
  const [contatosHospitais, setContatosHospitais] = useState([]);
  const [contatosHospitaisLastYear, setContatosHospitaisLastYear] = useState(
    []
  );
  const [loading, setLoading] = useState(true);

  const apiHospitais = process.env.REACT_APP_API_HOSPITAIS;
  const apiContatoHospitais = process.env.REACT_APP_API_CONTATOHOSPITAIS;
  const apiEmail = process.env.REACT_APP_API_EMAIL;

  useEffect(() => {
    fetch(`${apiHospitais}`)
      .then((response) => response.json())
      .then((data) => {
        setHospitais(data);
      });
  }, [apiHospitais]);

  useEffect(() => {
    fetch(`${apiContatoHospitais}/anoLetivo/${anoLetivo.anoLetivo}`)
      .then((response) => response.json())
      .then((data) => {
        setContatosHospitais(data);
        setLoading(false);
      });
  }, [apiContatoHospitais, anoLetivo]);

  useEffect(() => {
    fetch(`${apiContatoHospitais}/anoLetivo/${previousAnoLetivo}`)
      .then((response) => response.json())
      .then((data) => {
        setContatosHospitaisLastYear(data);
      });
  }, [apiContatoHospitais, anoLetivo]);

  const handleSearchTermChange = (event) => {
    setSearchTerm(event.target.value);
  };

  function filteredHospitais() {
    const searchTermLowerCase = removeAccents(searchTerm.toLowerCase());
    const filtered = hospitais.filter((hospital) => {
      const nomeLowerCase = removeAccents(hospital.nome.toLowerCase());
      const moradaLowerCase = removeAccents(hospital.morada.toLowerCase());
      const codPostalLowerCase = removeAccents(
        hospital.codPostal.toLowerCase()
      );
      const localidadeLowerCase = removeAccents(
        hospital.localidade.toLowerCase()
      );
      const matchesSearchTerm =
        hospital.idHospital.toString().includes(searchTerm) ||
        nomeLowerCase.includes(searchTermLowerCase) ||
        moradaLowerCase.includes(searchTermLowerCase) ||
        codPostalLowerCase.includes(searchTermLowerCase) ||
        localidadeLowerCase.includes(searchTermLowerCase);
      return hospital.visivel && matchesSearchTerm;
    });
    return Array.isArray(filtered) ? filtered : [filtered];
  }

  const filteredHospitaisArray = Array.isArray(filteredHospitais())
    ? filteredHospitais()
    : [];

  // Function to remove accents from a string
  function removeAccents(str) {
    const accents = "ÀÁÂÃÄÅàáâãäåÒÓÔÕÖØòóôõöøÈÉÊËèéêëÇçÌÍÎÏìíîïÙÚÛÜùúûüÿÑñ";
    const accentsOut = "AAAAAAaaaaaaOOOOOOooooooEEEEeeeeCcIIIIiiiiUUUUuuuuyNn";
    return str
      .split("")
      .map((letter) => {
        const accentIndex = accents.indexOf(letter);
        return accentIndex !== -1 ? accentsOut[accentIndex] : letter;
      })
      .join("");
  }

  const handleDelete = (hospital) => {
    setSelectedHospital(hospital);
    setShowConfirmationModal(true);
  };

  const handleConfirmDelete = () => {
    fetch(`${apiHospitais}/${selectedHospital.idHospital}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ ...selectedHospital, visivel: false }),
    })
      .then((response) => response.json())
      .then((data) => {
        setHospitais((prevHospitais) =>
          prevHospitais.filter(
            (hospital) => hospital.idHospital !== selectedHospital.idHospital
          )
        );
        setSelectedHospital(null);
        setShowConfirmationModal(false);
      })
      .catch((error) => {
        console.error("Error deleting hospital: ", error);
        // Handle the error condition
      });
  };

  const handleCancelDelete = () => {
    setSelectedHospital(null); // Limpar o hospital selecionado
    setShowConfirmationModal(false); // Fechar a janela de confirmação
  };

  const handleChangeHtmlBody = (content) => {
    setHtmlBody(content);
    htmlBodyRef.current = content;
  };

  const handleCancelSend = () => {
    setShowConfirmationModalEmail(false);
  };

  const formatDate = () => {
    const months = [
      "janeiro",
      "fevereiro",
      "março",
      "abril",
      "maio",
      "junho",
      "julho",
      "agosto",
      "setembro",
      "outubro",
      "novembro",
      "dezembro",
    ];
    const today = new Date();
    const day = today.getDate();
    const month = months[today.getMonth()];
    const year = today.getFullYear();
    return `Lisboa, ${day} de ${month} de ${year}`;
  };

  const sendEmail = (emailTO, emailCC, subject, htmlBody) => {
    return fetch(`${apiEmail}/vagas`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        emailTO: emailTO
          .filter((email) => email && email.trim() !== "")
          .join(";"),
        emailCC: emailCC
          .filter((email) => email && email.trim() !== "")
          .join(";"),
        subject: subject,
        htmlBody: htmlBody,
      }),
    })
      .then((response) => {
        if (response.ok) {
          setShowConfirmationModal(false);
        } else {
          throw new Error("Failed to send email");
        }
        return response.json();
      })
      .catch((error) => {
        console.error("Error:", error);
        throw error;
      });
  };

  const handleConfirmSend = () => {
    sendEmailsForHospitais();
  };

  const currentAnoLetivo = anoLetivo.anoLetivo;
  const [startYear, endYear] = currentAnoLetivo.split("-").map(Number);
  const previousAnoLetivo = `${startYear - 1}-${endYear - 1}`;

  // const sendEmailsForHospitais = () => {
  //   hospitais
  //     .filter((hospital) => hospital.visivel === true)
  //     .forEach((hospital) => {
  //       const contatoHospital = contatosHospitaisLastYear.find(
  //         (contato) => contato.idHospital === hospital.idHospital
  //       );

  //       if (contatoHospital) {
  //         const updatedHtmlBody = htmlBody
  //           .replace("NOMEHOSPITAL", hospital.nome)
  //           .replace("IDHOSPITAL", hospital.idHospital);

  //         const emailTO = [contatoHospital.email];
  //         const emailCC = [
  //           contatoHospital.emailEstagios,
  //           contatoHospital.emailAlternativo,
  //         ];

  //         const subject = `Convite a abertura de vagas de Estágio Curricular para ${currentAnoLetivo}`;

  //         sendEmail(emailTO, emailCC, subject, updatedHtmlBody);
  //         setShowConfirmationModalEmail(false);
  //       }
  //     });
  // };

  const sendEmailsForHospitais = () => {
    const visibleHospitais = hospitais.filter(
      (hospital) => hospital.visivel === true
    );
    const batchSize = 20;
    const delayBetweenBatches = 60000;
    const delayBetweenEmails = 1000;
    const totalEmails = visibleHospitais.length;
    let emailsSent = 0;

    const sendBatch = (batchIndex) => {
      const start = batchIndex * batchSize;
      const end = start + batchSize;
      const batch = visibleHospitais.slice(start, end);

      const sendEmailWithDelay = (index) => {
        if (index >= batch.length) {
          if (end < visibleHospitais.length) {
            setTimeout(() => sendBatch(batchIndex + 1), delayBetweenBatches);
          } else {
            setShowConfirmationModalEmail(false);
            console.log("All emails sent");
          }
          return;
        }

        const hospital = batch[index];
        const contatoHospital = contatosHospitaisLastYear.find(
          (contato) => contato.idHospital === hospital.idHospital
        );

        if (contatoHospital) {
          const updatedHtmlBody = htmlBody
            .replace("NOMEHOSPITAL", hospital.nome)
            .replace("IDHOSPITAL", hospital.idHospital);

          const emailTO = [contatoHospital.email];
          const emailCC = [
            contatoHospital.emailEstagios,
            contatoHospital.emailAlternativo,
          ];

          const subject = `Convite a abertura de vagas de Estágio Curricular para ${currentAnoLetivo}`;

          sendEmail(emailTO, emailCC, subject, updatedHtmlBody)
            .then(() => {
              emailsSent++;
              console.log(`Sent ${emailsSent}/${totalEmails} emails`);
              setTimeout(
                () => sendEmailWithDelay(index + 1),
                delayBetweenEmails
              );
            })
            .catch((error) => {
              console.error(
                `Error sending email to: ${emailTO.join(", ")} - ${error}`
              );
              setTimeout(
                () => sendEmailWithDelay(index + 1),
                delayBetweenEmails
              );
            });
        } else {
          setTimeout(() => sendEmailWithDelay(index + 1), delayBetweenEmails);
        }
      };

      sendEmailWithDelay(0);
    };

    sendBatch(0);
  };

  const handleSendEmail = () => {
    emailBody();
    setShowConfirmationModalEmail(true);
  };

  const emailBody = () => {
    const currentAnoLetivo = anoLetivo.anoLetivo;
    const [startYear, endYear] = currentAnoLetivo.split("-").map(Number);
    const nextAnoLetivo = `${startYear + 1}-${endYear + 1}`;

    const emailContent = `
          <div style='text-align: center;'>
              <img src='https://www.ff.ulisboa.pt/wp-content/uploads/2024/10/fful-assinatura-email.png' alt='Header image' style='width: 150px; height: auto;' />
              <p><strong>FACULDADE DE FARMÁCIA DA UNIVERSIDADE DE LISBOA</strong></p>
            </div>
            <div style='text-align: right; margin-top: 20px;'>
              <p>${formatDate()}</p>
            </div>
            <div></div>
            <div></div>
            <div>Exmo(a). Sr.(a) Dr.(a)</div>
            <div>Diretor(a) dos Serviços Farmacêuticos do Hospital <strong>NOMEHOSPITAL</strong></div>
            <div></div>
            <div></div>
            <div>A Faculdade de Farmácia da Universidade de Lisboa vem por este meio agradecer a disponibilidade das Farmácias registadas no Núcleo de Estágios da FFUL, na orientação do estágio curricular do Mestrado Integrado em Ciências Farmacêuticas. Gostaríamos de sublinhar que graças a este contributo, foi possível assegurar o estágio a cerca de <strong>(NUMALUNOS)</strong>  alunos no ano letivo ${previousAnoLetivo}.</div>
            <div>Expressamos a vontade de continuar a colaborar com a vossa instituição e renovamos o convite para orientarem o estágio relativo ao ano ${currentAnoLetivo}.</div>
            <div>A candidatura realiza-se online, sendo obrigatório o preenchimento de um formulário:</div>
            <div></div>
            <div><strong>Formulário disponível em -> https://nitapps.ff.ulisboa.pt/form-hospital</strong></div>
            <div></div>
            <div><strong>Importante,</strong> onde é solicitado o Código do Hospital deve ser colocado o nº <strong>IDHOSPITAL</strong>.</div>
            <div></div>
            <div>A secção de “Orientador(es) de Estágio” deve ser preenchida por todos os orientadores de Estágio, ou seja, deve ser preenchida uma entrada por cada Orientador (carregando no botão "Adicionar outro Orientador").</div>
            <div></div>
            <div>Após preenchimento do formulário deve submeter o mesmo através da opção “Submeter Formulário”.</div>
            <div></div>
            <div>Agradecemos a submissão até ao dia <strong>(DIASUBMISSAO)</strong>.</div>
            <div style='text-align: center; margin-top: 20px;'>
            <div>Com os melhores cumprimentos,</div>
            <div><img src='https://www.ff.ulisboa.pt/wp-content/uploads/2024/10/assinatura-carla-barros.png' alt='Signature Image' style='width: 300px; height: auto;' /></div>
            <div>Carla Teixeira de Barros</div>
            <div>(Coordenadora da Unidade Curricular “Estágio” do MICF, da FFUL)</div>
          </div>
    `;

    setHtmlBody(emailContent);
  };

  return (
    <div className="flex flex-col items-stretch min-h-full">
      <main className="flex-grow p-4">
        <div>
          <BootstrapModal show={showConfirmationModalEmail} centered>
            <style type="text/css">
              {`
      .modal-dialog {
        max-width: 90% !important;
        height: 80vh !important;
      }

      .modal-body {
        height: calc(80vh - 60px); /* 60px is an estimated height for the header and footer */
        overflow-y: auto; /* add a scrollbar if the content is too tall */
      }
    `}
            </style>
            <BootstrapModal.Body>
              <form>
                <div className="form-group">
                  <label htmlFor="body">Email:</label>
                  <Editor
                    apiKey="fra21wku4hoonua8iqg7xbfv9uhgzud33f456hj07igwxs2o"
                    value={htmlBody}
                    init={{
                      height: 700,
                      menubar: false,
                      plugins: [
                        "advlist autolink lists link image charmap print preview anchor",
                        "searchreplace visualblocks code fullscreen",
                        "insertdatetime media table paste code help wordcount",
                      ],
                      toolbar:
                        "undo redo | formatselect | bold italic backcolor | \
                    alignleft aligncenter alignright alignjustify | \
                    bullist numlist outdent indent | removeformat | help",
                      branding: false,
                      elementpath: false,
                      resize: false,
                    }}
                    onEditorChange={handleChangeHtmlBody}
                  />
                </div>
              </form>
            </BootstrapModal.Body>
            <BootstrapModal.Footer>
              <Button
                style={{
                  backgroundColor: "lightcoral",
                  borderColor: "lightcoral",
                  color: "black",
                }}
                variant="secondary"
                onClick={handleCancelSend}
              >
                Cancelar
              </Button>
              <Button
                style={{
                  backgroundColor: "lightgreen",
                  borderColor: "lightgreen",
                  color: "black",
                }}
                variant="danger"
                onClick={handleConfirmSend}
              >
                Enviar
              </Button>
            </BootstrapModal.Footer>
          </BootstrapModal>
        </div>
        <div className="py-2 mb-8 flex flex-col items-start">
          <button
            className="text-fful font-bold py-2 rounded"
            onClick={() => setShowEmailButton(!showEmailButton)}
          >
            {showEmailButton ? "Fechar Envio de Emails" : "Envio de Emails"}
          </button>
          {showEmailButton && (
            <button
              className="text-fful ml-4 pl-10 mt-2 pb-6"
              onClick={() => handleSendEmail()}
            >
              Envio de emails para pedido de vagas
            </button>
          )}
        </div>

        <div>
          <input
            type="text"
            placeholder="Pesquisar"
            value={searchTerm}
            onChange={handleSearchTermChange}
            className="border border-gray-400 rounded px-2 py-1 mb-3"
          />
        </div>
        <div className="flex items-center mb-4">
          <CreateHospital />
        </div>
        <div className="flex items-center mb-4">
          <ListagemHospitais
            hospitais={hospitais}
            anoLetivo={anoLetivo}
            contatosHospitais={contatosHospitais}
            loading={loading}
          />
        </div>
        <div>
          <Modal show={showConfirmationModal} centered>
            <Modal.Body>
              Tem a certeza que pretende eliminar o hospital?
            </Modal.Body>
            <Modal.Footer>
              <Button variant="danger" onClick={handleConfirmDelete}>
                Sim
              </Button>
              <Button variant="secondary" onClick={handleCancelDelete}>
                Não
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
        <div></div>
        <table className="table-auto w-full" id="hospitais">
          <thead>
            <tr>
              <th className="px-4 py-2">ID</th>
              <th className="px-4 py-2">Nome</th>
              <th className="px-4 py-2">Morada</th>
              <th className="px-4 py-2">Código Postal</th>
              <th className="px-4 py-2">Localidade</th>
              <th className="px-4 py-2">Data Expiração Protocolo</th>
              <th className="px-4 py-2">Área Metropolitana de Lisboa</th>
              <th className="px-4 py-2">Ações</th>
            </tr>
          </thead>
          <tbody>
            {filteredHospitaisArray.map((hospital) => {
              const isAreaLVT = hospital.areaGeoLVT
                ? "bg-green-200"
                : "bg-red-200";
              return (
                <tr key={hospital.idHospital}>
                  <td className="border px-4 py-2">{hospital.idHospital}</td>
                  <td className="border px-4 py-2">{hospital.nome}</td>
                  <td className="border px-4 py-2">{hospital.morada}</td>
                  <td className="border px-4 py-2">{hospital.codPostal}</td>
                  <td className="border px-4 py-2">{hospital.localidade}</td>
                  <td className="border px-4 py-2">
                    {hospital.dataExpProtocolo}
                  </td>
                  <td className={`border px-4 py-2 ${isAreaLVT}`}></td>
                  <td className="border px-4 py-2">
                    <div>
                      <EditHospital hospitalData={hospital} />
                      <GerirContactosHospital
                        hospitalData={hospital}
                        anoLetivo={anoLetivo}
                      />
                      <button
                        className="text-red-700 mx-1"
                        title="Excluir"
                        onClick={() => handleDelete(hospital)}
                      >
                        <FaTrash />
                      </button>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <ScrollToTopButton targetId="hospitais" />
      </main>
    </div>
  );
}

export default GerirHospitais;
