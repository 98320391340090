import React, { useState, useRef } from "react";
import EditContactoFarmacia from "../Edit/EditContactoFarmacia";
import { FaUserEdit } from "react-icons/fa";
import Modal from "react-modal";
import { ImCancelCircle } from "react-icons/im";
import CreateContactoFarmacia from "../Create/CreateContactoFarmacia";
import { MdEmail } from "react-icons/md";
import {
  Modal as BootstrapModal,
  Row,
  Form,
  Col,
  Button,
} from "react-bootstrap";
import { FaTrash } from "react-icons/fa";
import { Editor } from "@tinymce/tinymce-react";
import { jsPDF } from "jspdf";

function GerirContactosFarmacia({ farmaciaData, anoLetivo }) {
  const [contactosFarmacia, setContactosFarmacia] = useState([]);
  const [contactoFarmacia, setContactoFarmacia] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [alunos, setAlunos] = useState([]);
  const [blocosF, setBlocosF] = useState([]);
  const [vagasFarmacia, setVagasFarmacia] = useState([]);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showConfirmationModalContacto, setShowConfirmationModalContacto] =
    useState(false);
  const [data, setData] = useState([]);
  const [emailTO, setEmailTO] = useState();
  const [emailCC, setEmailCC] = useState([]);
  const [subject, setSubject] = useState([]);
  const [htmlBody, setHtmlBody] = useState([]);
  const htmlBodyRef = useRef(htmlBody);

  const apiContactoFarmacia = process.env.REACT_APP_API_CONTATOFARMACIAS;
  const apiVagasFarmacias = process.env.REACT_APP_API_VAGASFARMACIAS;
  const apiBlocoF = process.env.REACT_APP_API_BLOCOF;
  const apiAlunos = process.env.REACT_APP_API_ALUNOS;
  const apiEmail = process.env.REACT_APP_API_EMAIL;
  const apiOrientadorFarmacia = process.env.REACT_APP_API_ORIENTADOR_FARMACIA;

  const currentYear = new Date().getFullYear();

  const handleOpenModal = () => {
    fetch(`${apiContactoFarmacia}/idFarmacia/${farmaciaData.idFarmacia}`)
      .then((response) => response.json())
      .then((data) => setContactosFarmacia(data));
    setModalIsOpen(true);
  };

  const fetchContactosFarmacia = () => {
    fetch(`${apiContactoFarmacia}/idFarmacia/${farmaciaData.idFarmacia}`)
      .then((response) => response.json())
      .then((data) => setContactosFarmacia(data));
  };

  const handleCloseModal = () => {
    setModalIsOpen(false);
  };

  const handleSearchTermChange = (event) => {
    setSearchTerm(event.target.value);
  };

  function filteredContactosFarmacia() {
    const searchTermLowerCase = removeAccents(searchTerm.toLowerCase());
    const filtered = contactosFarmacia.filter((contactosFarmacia) => {
      const nomeLowerCase =
        contactosFarmacia && contactosFarmacia.nome
          ? removeAccents(contactosFarmacia.nome.toLowerCase())
          : "";
      const emailLowerCase =
        contactosFarmacia && contactosFarmacia.email
          ? removeAccents(contactosFarmacia.email.toLowerCase())
          : "";
      const matchesSearchTerm =
        nomeLowerCase.includes(searchTermLowerCase) ||
        emailLowerCase.includes(searchTermLowerCase);
      return matchesSearchTerm;
    });
    return Array.isArray(filtered) ? filtered : [filtered];
  }

  const filteredFarmaciasArray = Array.isArray(filteredContactosFarmacia())
    ? filteredContactosFarmacia()
    : [];

  // Function to remove accents from a string
  function removeAccents(str) {
    const accents = "ÀÁÂÃÄÅàáâãäåÒÓÔÕÖØòóôõöøÈÉÊËèéêëÇçÌÍÎÏìíîïÙÚÛÜùúûüÿÑñ";
    const accentsOut = "AAAAAAaaaaaaOOOOOOooooooEEEEeeeeCcIIIIiiiiUUUUuuuuyNn";
    return str
      .split("")
      .map((letter) => {
        const accentIndex = accents.indexOf(letter);
        return accentIndex !== -1 ? accentsOut[accentIndex] : letter;
      })
      .join("");
  }

  const handleCancelSend = () => {
    setShowConfirmationModal(false);
  };

  const handleConfirmSend = () => {
    sendEmail();
  };

  const sendEmail = () => {
    fetch(`${apiEmail}/vagas`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        emailTO: emailTO
          .filter((email) => email && email.trim() !== "")
          .join(";"),
        emailCC: emailCC
          .filter((email) => email && email.trim() !== "")
          .join(";"),
        subject: subject,
        htmlBody: htmlBodyRef.current,
      }),
    })
      .then((response) => {
        if (response.ok) {
          setShowConfirmationModal(false);
        } else {
          throw new Error("Failed to send email");
        }
        return response.json();
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handleSendEmail = (contactoFarmacia) => {
    setEmailTO([contactoFarmacia.email]);
    setEmailCC([
      contactoFarmacia.emailEstagios,
      contactoFarmacia.emailAlternativo,
    ]);
    setSubject(
      "Vagas disponibilizadas para os alunos a iniciar o estágio curricular"
    );
    Promise.all([
      fetch(
        `${apiVagasFarmacias}/anoLetivo/${anoLetivo.anoLetivo}/farmacia/${farmaciaData.idFarmacia}`
      ),
      fetch(`${apiAlunos}/anoLetivo/${anoLetivo.anoLetivo}`),
      fetch(`${apiBlocoF}/anoLetivo/${anoLetivo.anoLetivo}`),
    ])
      .then((responses) => Promise.all(responses.map((res) => res.json())))
      .then(([vagasFarmaciaData, alunosData, blocoFData]) => {
        setVagasFarmacia(vagasFarmaciaData);
        setAlunos(alunosData);
        setBlocosF(blocoFData);

        const data = vagasFarmaciaData.map((item) => {
          const blocoF =
            Array.isArray(blocoFData) &&
            blocoFData.find((b) => b.idBlocoF === item.idBlocoF);
          const aluno =
            Array.isArray(alunosData) &&
            alunosData.find((a) => a.idAluno === item.idAluno);

          return {
            ...item,
            blocoF,
            aluno,
          };
        });

        setData(data);
        const emailContent = `
      <p>Exmo(a). Sr(a).</p> 
      <p>Diretor(a) Farmacêutico(a)</p>
      <p>De acordo com as vagas disponibilizadas para os alunos a iniciar o estágio curricular do Mestrado Integrado em Ciências Farmacêuticas da Universidade de Lisboa, relativo a ${currentYear}, informamos que vão realizar o estágio curricular na Farmácia ${
          farmaciaData.nome
        } os seguintes alunos:</p>
      <table>
        <tr>
          <th>Nome do Aluno</th>
          <th>Número de Aluno</th>
          <th>Bloco</th>
          <th>Data Início</th>
          <th>Data Fim</th>
        </tr>
        ${data
          .map(
            (item) => `
          <tr>
            <td>${item.aluno.nome}</td>
            <td>${item.aluno.numAluno}</td>
            <td>${item.blocoF.bloco}</td>
            <td>${item.blocoF.dataInicio}</td>
            <td>${item.blocoF.dataFim}</td>
          </tr>
        `
          )
          .join("")}
      </table>
      <p>Os documentos que enquadram o funcionamento do estágio, nomeadamente o Regulamento e o Manual de Estágio, estão disponíveis nas ligações abaixo indicadas, com a password <strong>FFUL_NE1!</strong></p>                
      <p>- <a href='https://www.ff.ulisboa.pt/wp-content/uploads/2019/01/Regulamento-MICF_2018.pdf'>Link para o Regulamento do Estágio</a> (ver sff. págs. 24211 a 24214)</p>
      <p>- <a href='https://www.ff.ulisboa.pt/wp-content/uploads/2024/01/Manual-Estagio_F.-Comunitaria.pdf'>Link para o Manual de Estágio em Farmácia Comunitária</a></p>
      <p>O Manual tem como objetivo ser um guia para Orientadores e Estagiários, visando também a uniformização dos Estágios. Está organizado por módulos, sendo para cada um deles indicados objetivos a atingir, os respetivos conteúdos e as metodologias recomendadas. No final de cada módulo, incluiu-se uma grelha de objetivos, cujo preenchimento por parte do Estagiário e do seu Orientador, permitirá a ambos a monitorização da eficácia pedagógica do Estágio. É com base nas atividades desenvolvidas que incide a prova pública dos alunos.</p>
      <p>Dada a diversidade dos locais de Estágio, haverá sempre alguns onde, pelas suas características, não será possível atingir todos os objetivos indicados. Também a sequência pela qual os diferentes temas são abordados deverá ser encarada com flexibilidade, pois a articulação dos conteúdos depende obviamente das características do local de Estágio.</p>
      <p>Todos os alunos estão abrangidos pelo seguro escolar, cuja ligação está identificada abaixo:</p>
      <p>- <a href='https://www.ff.ulisboa.pt/seguro-escolar/'>Link para a Apólice do Seguro Escolar</a></p>
      <p>Em caso de acidente devem ser contactados de imediato a Seguradora Fidelidade e os Serviços Académicos da FFUL através dos contactos:</p>
      <p>Seguradora Fidelidade</p>
      <p>Telefone: 217 943 039 / 213 102 450</p>
      <p>Email: sinistrospessoaisap@ageas.pt</p>
      <p>Serviços Académicos</p>
      <p>Telefone: 217 946 400. Ext.: 14362</p>
      <p>Email: academicos@ff.ulisboa.pt</p>
      <p>A avaliação dos alunos pelo(s) orientador(es) de estágio efetua-se no final do período de estágio, sendo disponibilizada para o efeito a Ficha de Avaliação e de Assiduidade (com a password: <strong>FFUL_NE1!</strong>)</p>
      <p>- <a href='https://www.ff.ulisboa.pt/wp-content/uploads/2024/01/Ficha-de-avaliacao-do-aluno-2024.pdf'>Link para a Ficha de Avaliação</a></p>
      <p>- <a href='https://www.ff.ulisboa.pt/wp-content/uploads/2024/01/Ficha-de-assiduidade-do-aluno-2024.pdf'>Link para a Ficha de Assiduidade</a></p>
      <p>De acordo com o nº. 5, do Artigo 7º, do Regulamento de Estágio e para os alunos não trabalhadores estudantes, a carga horária semanal é no máximo de 30 horas, no período compreendido entre segunda-feira e sexta-feira.</p>
      <p>Relativamente aos alunos trabalhadores estudantes e de acordo com o nº. 6 do mesmo Artigo, o estágio tem a duração mínima de 600 horas: 468 horas para a atividade em local de estágio e 132 horas para as atividades letivas e para a elaboração da monografia/trabalho de campo.</p>
      <p>Agradecendo desde já a colaboração prestada, apresentamos os melhores cumprimentos,</p>
      <table>
      <tr>
      <td><img src='https://www.ff.ulisboa.pt/wp-content/uploads/2024/10/fful-assinatura-email.png' alt='Signature Image' style='width: 150px; height: auto;' /></td>
      <td style='font-size: 0.8em;'>
      <p><strong>Ana Marta Monteiro</strong></p>
      <p>Núcleo de Estágios / Curricular Internships  | Conselhos de Escola e Estratégico / School and Strategic Coucil</p>
      <p>Dep. de Farmácia, Farmacologia e Tecnologias em Saúde/Dep. of Social Pharmacy, Pharmacology and Health Technologies</p>
      <p>Faculdade de Farmácia da Universidade de Lisboa | Faculty of Pharmacy of the University of Lisbon</p>
      <p>Av. Prof. Gama Pinto, 1649-003 Lisboa, Portugal | (351) 21 794 64 00 I Ext. 514332</p>
      <p>nucleodeestagios@ff.ulisboa.pt | www.ff.ul.pt</p>
      <p>(Em caso de resposta, agradece-se que reencaminhe sempre a troca de emails feita com estes serviços)</p>
      </td>
      </tr>
      </table>
    `;
        setHtmlBody(emailContent);
        htmlBodyRef.current = emailContent;
      });
    setShowConfirmationModal(true);
  };

  const handleDelete = (id) => {
    setContactoFarmacia(id);
    setShowConfirmationModalContacto(true);
  };

  const handleConfirmDelete = () => {
    fetch(`${apiContactoFarmacia}/${contactoFarmacia}`, {
      method: "DELETE",
    })
      .then((response) => {
        setShowConfirmationModalContacto(false);
        fetchContactosFarmacia();
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        // handle successful deletion here, e.g. by updating the UI
      })
      .catch((error) => {
        console.error(
          "There has been a problem with your fetch operation:",
          error
        );
        // handle error here
      });
  };

  const handleCancelDelete = () => {
    setContactoFarmacia(null); // Limpar a farmacia selecionada
    setShowConfirmationModalContacto(false); // Fechar a janela de confirmação
  };

  const handleChangeHtmlBody = (content) => {
    setHtmlBody(content);
    htmlBodyRef.current = content;
  };

  const handleSendOrientadorDeclaracoes = async (contactoFarmacia) => {
    try {
      // Fetch orientadores for this hospital and academic year
      const response = await fetch(
        `${apiOrientadorFarmacia}/anoLetivo/${anoLetivo.anoLetivo}`
      );
      const orientadores = await response.json();

      // Filter orientadores for this specific hospital
      const farmaciaOrientadores = orientadores.filter(
        (orientador) => orientador.idFarmacia === farmaciaData.idFarmacia
      );

      if (farmaciaOrientadores.length === 0) {
        alert(
          "Não existem orientadores registados para esta farmácia no ano letivo atual."
        );
        return;
      }

      // Create PDFs for each orientador
      const pdfs = farmaciaOrientadores.map((orientador) => {
        const doc = new jsPDF();
        doc.setFont("helvetica");
        doc.setFontSize(12);

        // Add content to PDF
        doc.text(orientador.nome, 20, 20);
        doc.text(`Número de Ordem: ${orientador.numOrdem}`, 20, 30);
        doc.text(`Farmácia: ${farmaciaData.nome}`, 20, 40);

        return doc.output("blob");
      });

      // Add logging for FormData contents
      const formData = new FormData();
      pdfs.forEach((pdf, index) => {
        formData.append(
          "attachments",
          pdf,
          `declaracao_orientador_${index + 1}.pdf`
        );
        console.log(`Added PDF ${index + 1}:`, {
          name: `declaracao_orientador_${index + 1}.pdf`,
          size: pdf.size,
          type: pdf.type,
        });
      });

      // Add email details with logging
      formData.append("emailTO", contactoFarmacia.email);
      formData.append(
        "emailCC",
        [contactoFarmacia.emailEstagios, contactoFarmacia.emailAlternativo]
          .filter(Boolean)
          .join(";")
      );
      formData.append("subject", "Declarações dos Orientadores");
      formData.append(
        "htmlBody",
        `
        <p>Exmo(a). Sr(a).</p>
        <p>Em anexo, seguem as declarações dos orientadores da Farmácia ${farmaciaData.nome} para o ano letivo ${anoLetivo}.</p>
        <p>Com os melhores cumprimentos,</p>
      `
      );

      // Log the complete FormData
      console.log("Email TO:", contactoFarmacia.email);
      console.log(
        "Email CC:",
        [contactoFarmacia.emailEstagios, contactoFarmacia.emailAlternativo]
          .filter(Boolean)
          .join(";")
      );

      // Log the actual request
      console.log("Sending request to:", `${apiEmail}/attachments`);

      // Add logging for the response
      const emailResponse = await fetch(`${apiEmail}/attachments`, {
        method: "POST",
        body: formData,
      });

      console.log("Response status:", emailResponse.status);
      const responseText = await emailResponse.text();
      console.log("Response body:", responseText);

      if (emailResponse.ok) {
        alert("Declarações enviadas com sucesso!");
      } else {
        throw new Error(`Falha no envio das declarações: ${responseText}`);
      }
    } catch (error) {
      console.error("Erro detalhado:", error);
      alert("Ocorreu um erro ao processar as declarações dos orientadores.");
    }
  };

  return (
    <>
      <button
        className="text-purple-700 mx-1"
        title="Editar Contactos da Farmácia"
        onClick={handleOpenModal}
      >
        <FaUserEdit />
      </button>
      <div>
        <BootstrapModal show={showConfirmationModal} centered>
          <style type="text/css">
            {`
      .modal-dialog {
        max-width: 90% !important;
        height: 80vh !important;
      }

      .modal-body {
        height: calc(80vh - 60px); /* 60px is an estimated height for the header and footer */
        overflow-y: auto; /* add a scrollbar if the content is too tall */
      }
    `}
          </style>
          <BootstrapModal.Body>
            <form>
              <div className="form-group">
                <label htmlFor="to">Para:</label>
                <input
                  type="email"
                  id="to"
                  className="form-control"
                  value={emailTO}
                  onChange={(e) => setEmailTO(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label htmlFor="cc">CC:</label>
                <input
                  type="email"
                  id="cc"
                  className="form-control"
                  value={emailCC.join(";")}
                  onChange={(e) =>
                    setEmailCC(e.target.value ? e.target.value.split(";") : [])
                  }
                />
              </div>
              <div className="form-group">
                <label htmlFor="subject">Assunto:</label>
                <input
                  type="text"
                  id="subject"
                  value={subject}
                  className="form-control"
                  onChange={(e) => setSubject(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label htmlFor="body">Email:</label>
                <Editor
                  apiKey="fra21wku4hoonua8iqg7xbfv9uhgzud33f456hj07igwxs2o"
                  value={htmlBody}
                  init={{
                    height: 500,
                    menubar: false,
                    plugins: [
                      "advlist autolink lists link image charmap print preview anchor",
                      "searchreplace visualblocks code fullscreen",
                      "insertdatetime media table paste code help wordcount",
                    ],
                    toolbar:
                      "undo redo | formatselect | bold italic backcolor | \
                    alignleft aligncenter alignright alignjustify | \
                    bullist numlist outdent indent | removeformat | help",
                    branding: false,
                    elementpath: false,
                    resize: false,
                  }}
                  onEditorChange={handleChangeHtmlBody}
                />
              </div>
            </form>
          </BootstrapModal.Body>
          <BootstrapModal.Footer>
            <Button
              style={{
                backgroundColor: "lightcoral",
                borderColor: "lightcoral",
                color: "black",
              }}
              variant="secondary"
              onClick={handleCancelSend}
            >
              Cancelar
            </Button>
            <Button
              style={{
                backgroundColor: "lightgreen",
                borderColor: "lightgreen",
                color: "black",
              }}
              variant="danger"
              onClick={handleConfirmSend}
            >
              Enviar
            </Button>
          </BootstrapModal.Footer>
        </BootstrapModal>
      </div>
      <div>
        <BootstrapModal show={showConfirmationModalContacto} centered>
          <BootstrapModal.Body>
            Tem a certeza que pretende eliminar o contacto?
          </BootstrapModal.Body>
          <BootstrapModal.Footer>
            <Button variant="danger" onClick={handleConfirmDelete}>
              Sim
            </Button>
            <Button variant="secondary" onClick={handleCancelDelete}>
              Não
            </Button>
          </BootstrapModal.Footer>
        </BootstrapModal>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={handleCloseModal}
        className="fixed inset-0 bg-gray-900 bg-opacity-50 flex justify-center items-center z-50"
      >
        <div className="bg-white rounded-lg p-6 md:p-8 flex flex-col justify-center items-center">
          <main className="flex-grow p-4">
            <Row className="mb-4 d-flex align-items-center">
              <Form.Group as={Col}>
                <Form.Label className="font-bold">Nome da Farmácia</Form.Label>
                <Form.Control
                  type="text"
                  value={farmaciaData.nome}
                  readOnly
                  plaintext
                  className="!bg-gray-200 rounded"
                />
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label className="font-bold">
                  Morada da Farmácia
                </Form.Label>
                <Form.Control
                  type="text"
                  value={farmaciaData.morada}
                  readOnly
                  plaintext
                  className="!bg-gray-200 rounded"
                />
              </Form.Group>
            </Row>
            <div>
              <input
                type="text"
                placeholder="Pesquisar"
                value={searchTerm}
                onChange={handleSearchTermChange}
                className="border border-gray-400 rounded px-2 py-1 mb-3"
              />
            </div>
            <div className="flex items-center mb-4">
              <CreateContactoFarmacia
                farmaciaData={farmaciaData}
                onClose={fetchContactosFarmacia}
              />
            </div>
            <table className="table-auto w-full ">
              <thead>
                <tr>
                  <th className="px-4 py-2">Ano Letivo</th>
                  <th className="px-4 py-2">Nome</th>
                  <th className="px-4 py-2">Email</th>
                  <th className="px-4 py-2">Email Estágios</th>
                  <th className="px-4 py-2">Email Alternativo</th>
                  <th className="px-4 py-2">Telefone</th>
                  <th className="px-4 py-2">Telemóvel</th>
                  <th className="px-4 py-2">Cargo</th>
                  <th className="px-4 py-2">Observações</th>
                  <th className="px-4 py-2">Ações</th>
                </tr>
              </thead>
              <tbody>
                {filteredFarmaciasArray.map((contactoFarmacia) => {
                  return (
                    <tr key={contactoFarmacia.idContatoFarmacia}>
                      <td className="border px-4 py-2">
                        {contactoFarmacia.anoLetivo}
                      </td>
                      <td className="border px-4 py-2">
                        {contactoFarmacia.nome}
                      </td>
                      <td className="border px-4 py-2">
                        {contactoFarmacia.email}
                      </td>
                      <td className="border px-4 py-2">
                        {contactoFarmacia.emailEstagios}
                      </td>
                      <td className="border px-4 py-2">
                        {contactoFarmacia.emailAlternativo}
                      </td>
                      <td className="border px-4 py-2">
                        {contactoFarmacia.telefone}
                      </td>
                      <td className="border px-4 py-2">
                        {contactoFarmacia.telemovel}
                      </td>
                      <td className="border px-4 py-2">
                        {contactoFarmacia.cargo}
                      </td>
                      <td className="border px-4 py-2">
                        {contactoFarmacia.obs}
                      </td>
                      <td className="border px-4 py-2">
                        <div>
                          <EditContactoFarmacia
                            contactoFarmaciaData={contactoFarmacia}
                            onClose={fetchContactosFarmacia}
                          />
                          <button
                            type="Enviar email"
                            className="text-purple-700 mx-1"
                            onClick={() => handleSendEmail(contactoFarmacia)}
                          >
                            <MdEmail />
                          </button>
                          {/* <button
                            className="text-purple-700 mx-1"
                            title="Enviar declarações dos orientadores"
                            onClick={() =>
                              handleSendOrientadorDeclaracoes(contactoFarmacia)
                            }
                          >
                            <FaUserEdit />
                          </button> */}
                          <button
                            className="text-red-700 mx-1"
                            title="Excluir"
                            onClick={() =>
                              handleDelete(contactoFarmacia.idContatoFarmacia)
                            }
                          >
                            <FaTrash />
                          </button>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </main>
          <button
            type="button"
            className="text-white px-4 py-2 mx-2 rounded-md bg-fful hover:bg-iconhover"
            onClick={handleCloseModal}
          >
            <ImCancelCircle />
          </button>
        </div>
      </Modal>
    </>
  );
}

export default GerirContactosFarmacia;
